import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
import { randomNumberRange, replaceObjects } from "../../_mock/funcs";
import { fNumberDecimal } from "../../utils/formatNumber";

// ----------------------------------------------------------------------

const initialState = {
  betByCategory: [],
  betValueChangeHistory: [],
  stakeAmountHistory: [],
  stakeAmount: 0,
  betConditions: {
    minBetValue: 1,
    maxBetValue: 500,
  },
};

const slice = createSlice({
  name: "bet",
  initialState,
  reducers: {
    setBetByCategory(state, action) {
      state.betByCategory = action.payload;
    },
    addBetByCategory(state, action) {
      const { items, category, isToggle } = action.payload;

      let _prev = JSON.parse(JSON.stringify(state.betByCategory));
      let categIdx = _prev.findIndex((x) => x.category === category);
      if (categIdx !== -1) {
        let lastIndex =
          _prev[categIdx]?.bets[_prev[categIdx]?.bets?.length - 1]?.betId || 0;
        // state.betByCategory = mergedBets;
        items.forEach((item) => {
          const isExist = _prev[categIdx].bets.findIndex((x) => x.bet === item);
          if (isExist === -1) {
            _prev[categIdx].bets.push({
              betId: ++lastIndex,
              bet: item,
              value: state.stakeAmount || 1,
            });
          } else if (isExist !== -1 && isToggle) {
            _prev[categIdx].bets = _prev[categIdx].bets.filter(
              (x) => x.bet !== item
            );
          }
        });
        if (!_prev[categIdx]?.bets?.length) {
          _prev = [];
          state.betValueChangeHistory = [];
        }
      } else {
        _prev.push({
          category,
          bets: items.map((item, idx) => ({
            betId: idx + 1,
            bet: item,
            value: state.stakeAmount || 1,
          })),
        });
      }

      state.betByCategory = _prev;
    },
    deleteBetNumbers(state, action) {
      const { items, category } = action.payload;
      let _prev = JSON.parse(JSON.stringify(state.betByCategory));
      let categIdx = _prev.findIndex((x) => x.category === category);
      if (categIdx !== -1) {
        items.forEach((item) => {
          _prev[categIdx].bets = _prev[categIdx].bets.filter(
            (x) => x.bet !== item
          );
        });
      }

      if (!_prev[categIdx]?.bets?.length) {
        _prev = [];
        state.betValueChangeHistory = [];
      }

      state.betByCategory = _prev;
    },
    editBetValueAction(state, action) {
      const { item, category } = action.payload;
      const _prev = JSON.parse(JSON.stringify(state.betByCategory));
      state.betValueChangeHistory = [
        ...state.betValueChangeHistory,
        {
          betByCategory: state.betByCategory,
          stakeAmount: state.stakeAmount,
        },
      ];
      let categIdx = _prev.findIndex((x) => x.category === category);
      if (categIdx !== -1 && _prev[categIdx]?.bets?.length) {
        _prev[categIdx].bets = _prev[categIdx]?.bets.map((x) => {
          if (x.bet === item.bet) return item;
          return x;
        });
      }

      state.betByCategory = _prev;
    },
    editStakeAmount(state, action) {
      const { value, category } = action.payload;
      const _prev = JSON.parse(JSON.stringify(state.betByCategory));
      state.betValueChangeHistory = [
        ...state.betValueChangeHistory,
        {
          betByCategory: state.betByCategory,
          stakeAmount: state.stakeAmount,
        },
      ];
      let categIdx = _prev.findIndex((x) => x.category === category);
      if (categIdx !== -1 && _prev[categIdx]?.bets?.length) {
        _prev[categIdx].bets = _prev[categIdx]?.bets.map((x) => {
          x.value = value;
          return x;
        });
      }
      state.stakeAmount = value;
      state.betByCategory = _prev;
    },
    revertValueChange(state) {
      if (state.betValueChangeHistory?.length) {
        const lastValue = state.betValueChangeHistory.pop();
        state.betByCategory = replaceObjects(
          state.betByCategory,
          lastValue.betByCategory
        );
        state.stakeAmount = lastValue.stakeAmount;
      }
    },
    clearBets(state) {
      state.betByCategory = [];
      state.betValueChangeHistory = [];
      state.stakeAmount = 0;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setBetByCategory,
  addBetByCategory,
  deleteBetNumbers,
  clearBets,
  editBetValueAction,
  revertValueChange,
  editStakeAmount,
} = slice.actions;

export const toggleBetByNumbers = (items, category = "3Digit") => {
  dispatch(addBetByCategory({ items, category, isToggle: true }));
};

export const addBetByNumbers = (items, category = "3Digit") => {
  dispatch(addBetByCategory({ items, category, isToggle: false }));
};

export const deleteByNumbers = (items, category = "3Digit") => {
  dispatch(deleteBetNumbers({ items, category }));
};

export const editBetValueByNumber = ({ item, category = "3Digit" }) => {
  dispatch(editBetValueAction({ item, category }));
};

export const undoValueChange = () => {
  dispatch(revertValueChange());
};

export const resetBets = () => {
  dispatch(clearBets());
};

export const changeStakeAmount = (value, category = "3Digit") => {
  dispatch(editStakeAmount({ value, category }));
};