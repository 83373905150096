import { company } from "./company";
import { email } from "./email";
import { firstName, fullName, lastName } from "./name";
import { age } from "./number";
import { phoneNumber } from "./phoneNumber";
import { description } from "./text";

// ----------------------------------------------------------------------

export function randomNumber(number) {
  return Math.floor(Math.random() * number) + 1;
}

export function randomNumberRange(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function randomInArray(array) {
  return array[Math.floor(Math.random() * array.length)];
}

export const mockDates = [
  "04/02/2022 10:10:42 PM",
  "08/19/2022 01:32:31 PM",
  "02/25/2022 03:02:10 AM",
  "01/31/2022 03:48:02 PM",
  "05/21/2022 04:17:30 AM",
  "12/11/2022 03:11:50 AM",
  "12/02/2022 06:14:30 PM",
  "11/19/2022 04:02:37 AM",
  "03/18/2022 10:56:01 PM",
  "12/24/2022 08:15:18 PM",
];

/**
 *
 * @param {Object} criteria
 * @param {Number} count
 * @returns {Array.<Object>}
 */
export function mockDataGenerator(criteria, count = 1) {
  if (Object.keys(criteria).length === 0 || !criteria) return false;

  const newMock = [];

  do {
    const mock = {};
    Object.keys(criteria).forEach((key) => {
      let randomValue = criteria[key];
      if (Array.isArray(criteria[key]) && criteria[key]?.length) {
        randomValue = randomInArray(criteria[key]);
      } else {
        switch (criteria[key]) {
          case "name":
            randomValue = randomInArray(fullName);
            break;
          case "firstname":
            randomValue = randomInArray(firstName);
            break;
          case "lastname":
            randomValue = randomInArray(lastName);
            break;
          case "date":
            randomValue = randomInArray(mockDates);
            break;
          case "number":
            randomValue = randomNumber(1000);
            break;
          case "percent":
            randomValue = randomNumber(100);
            break;
          case "age":
            randomValue = randomInArray(age);
            break;
          case "price":
            randomValue = randomNumberRange(1000, 1000 * 100);
            break;
          case "phonenumber":
            randomValue = randomInArray(phoneNumber);
            break;
          case "email":
            randomValue = randomInArray(email);
            break;
          case "company":
            randomValue = randomInArray(company);
            break;
          case "description":
            randomValue = randomInArray(description);
            break;
          case "sentence":
            randomValue = randomInArray(description);
            break;
          default:
            break;
        }
      }

      mock[key] = randomValue;
    });
    newMock.push(mock);
    count--;
  } while (count > 0);

  return newMock;
}

export function replaceObjects(originalObj, newObj) {
  const modifiedObj = JSON.parse(JSON.stringify(originalObj));

  for (let key in newObj) {
    if (modifiedObj.hasOwnProperty(key)) {
      if (typeof newObj[key] === "object") {
        if (Array.isArray(newObj[key])) {
          // Handle the case where the original object's key is an array of objects.
          if (Array.isArray(modifiedObj[key])) {
            modifiedObj[key] = modifiedObj[key].map((obj, index) => {
              if (newObj[key][index]) {
                if (typeof newObj[key][index] === "object") {
                  return replaceObjects(obj, newObj[key][index]);
                } else {
                  return newObj[key][index];
                }
              } else {
                return obj;
              }
            });
          } else {
            // Handle the case where the original object's key is not an array of objects.
            modifiedObj[key] = newObj[key];
          }
        } else {
          // Handle the case where the key is not an array.
          modifiedObj[key] = replaceObjects(modifiedObj[key], newObj[key]);
        }
      } else {
        modifiedObj[key] = newObj[key];
      }
    }
  }

  return modifiedObj;
}
