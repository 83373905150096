import PropTypes from "prop-types";
import { noCase } from "change-case";
import { useState } from "react";
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Typography,
  IconButton,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from "@mui/material";
import moment from "moment";
// utils
import { fDate, fToNow } from "../../utils/formatTime";
// components
import Iconify from "../../components/Iconify";
import Scrollbar from "../../components/Scrollbar";
import MenuPopover from "../../components/MenuPopover";
import { IconButtonAnimate } from "../../components/animate";
import { allLangs } from "../../config";
import { useSelector } from "../../redux/store";
import { readNotifications } from "../../redux/slices/account";

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const { notifications } = useSelector((x) => x.account);

  const totalUnRead = notifications?.filter((f) => f.isRead === false).length;

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    if (totalUnRead > 0) {
      readNotifications();
    }
    setOpen(null);
  };

  // const handleMarkAllAsRead = () => {
  //   setNotifications(
  //     notifications.map((notification) => ({
  //       ...notification,
  //       isUnRead: false,
  //     }))
  //   );
  // };

  return (
    <>
      <IconButtonAnimate
        color={open ? "primary" : "default"}
        onClick={handleOpen}
        // sx={{ width: 40, height: 40 }}
        sx={{ p: 0.2 }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            {totalUnRead && totalUnRead > 0 ? (
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                You have {totalUnRead} unread notification(s)
              </Typography>
            ) : (
              ""
            )}
          </Box>

          {/* {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" width={20} height={20} />
              </IconButton>
            </Tooltip>
          )} */}
        </Box>

        {/* <Divider sx={{ borderStyle: "dashed" }} /> */}

        <Scrollbar sx={{ height: { lg: 300, xl: 300, xs: 300, sm: "auto" } }}>
          {totalUnRead && totalUnRead > 0 ? (
            <List
              disablePadding
              subheader={
                <ListSubheader
                  disableSticky
                  sx={{ py: 1, px: 2.5, typography: "overline" }}
                >
                  New
                </ListSubheader>
              }
            >
              {notifications
                .filter((x) => x.isRead === false)
                .map((notification) => (
                  <NotificationItem
                    key={notification.drawId}
                    notification={notification}
                  />
                ))}
            </List>
          ) : (
            ""
          )}
          <List disablePadding>
            {notifications
              .filter((x) => x.isRead === true)
              .map((notification) => (
                <NotificationItem
                  key={notification.drawId}
                  notification={notification}
                />
              ))}
          </List>
        </Scrollbar>
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    timestamp: PropTypes.string,
    drawId: PropTypes.number,
    isRead: PropTypes.bool,
    title: PropTypes.string,
    message: PropTypes.string,
  }),
};

function NotificationItem({ notification }) {
  const { avatar, title } = renderContent(notification);

  // const defaultLang = allLangs.find(
  //   (f) => f.value === localStorage.getItem("epsilon").toLowerCase()
  // ).tz;

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: "1px",
        ...(!notification.isRead && {
          bgcolor: "action.selected",
        }),
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: "background.neutral" }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Tooltip
            title={moment(notification.timestamp).format(
              "MMM DD, YYYY HH:mm a"
            )}
            placement="top"
            followCursor
          >
            <Typography
              variant="caption"
              sx={{
                mt: 0.5,
                display: "flex",
                alignItems: "center",
                color: "text.disabled",
              }}
            >
              <Iconify
                icon="eva:clock-outline"
                sx={{ mr: 0.5, width: 16, height: 16 }}
              />
              {moment(notification.timestamp).fromNow()}
            </Typography>
          </Tooltip>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography
        component="span"
        variant="body2"
        sx={{ color: "text.secondary" }}
      >
        &nbsp; {noCase(notification.message)}{" "}
        {`for ${notification.gameName} - `} {`Round ${notification.drawLot}`}
      </Typography>
    </Typography>
  );

  return {
    avatar: (
      <img
        alt={notification.title}
        src={require("../../assets/cashback.png")}
        style={{ width: "25px", height: "25px" }}
      />
    ),
    title,
  };
}
