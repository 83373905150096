import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
import { fNumberDecimal } from "../../utils/formatNumber";
import { randomNumberRange } from "../../_mock/funcs";
import conObj from "../../utils/connection-assistant";

// ----------------------------------------------------------------------

const initialState = {
  creditBalance: 0,
  notifications: [],
};

const slice = createSlice({
  name: "account",
  initialState,
  reducers: {
    updateAccount(state, action) {
      state.creditBalance = action.payload.creditBalance;
      state.notifications = action.payload.notifications;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { updateAccount } = slice.actions;

export const fetchUserCurrentBalance = () => {
  return conObj.get("Player/Notification").then((res) => {
    if (res.status === 200) {
      const { creditBalance, notifications } = res.data;
      dispatch(
        updateAccount({
          creditBalance,
          notifications,
        })
      );
    }
  });
};

export const readNotifications = () => {
  // Read Player API Notifs here
  return conObj.post("Player/ReadNotification").then(() => fetchUserCurrentBalance());
};
