import numeral from "numeral";

// ----------------------------------------------------------------------

export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? "$0,0" : "$0,0.00");
}

export function fPercent(number) {
  return numeral(number / 100).format("0.0%");
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fNumberDecimal(number, isOptional = true) {
  return numeral(number).format(isOptional ? "0,0.[00]" : "0,0.00");
}

export function fShortenNumber(number) {
  return numeral(number).format("0.00a").replace(".00", "");
}

export function fData(number) {
  return numeral(number).format("0.0 b");
}

export function multiplyNumbers(num1, num2) {
  // Check if either number is null
  if (num1 === null || num2 === null) {
    return 0;
  }

  // Check if either number is undefined
  if (typeof num1 === "undefined" || typeof num2 === "undefined") {
    return 0;
  }

  // Check if either number is not a number
  if (isNaN(num1) || isNaN(num2)) {
    return 0;
  }

  // Perform the multiplication
  var result = num1 * num2;
  return result;
}
