import React, { useState, forwardRef } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Slide,
  TextField,
  TextareaAutosize,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../components/Iconify';
// hooks
import useSettings from '../../hooks/useSettings';
import useResponsive from '../../hooks/useResponsive';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// config
import { HEADER, NAVBAR } from '../../config';
// redux
import { useDispatch, useSelector } from '../../redux/store';
import { closeCall, closeHardware } from '../../redux/slices/settings';

//
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
import NavbarHorizontal from './navbar/NavbarHorizontal';

import { DialogAnimate } from '../../components/animate';

// ----------------------------------------------------------------------

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  width: '100%',
  flexGrow: 1,
  marginLeft: 'auto',
  marginRight: 'auto',
  // paddingTop: HEADER.MOBILE_HEIGHT + 24,
  // paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  paddingTop: 0,
  paddingBottom: 0,
  [theme.breakpoints.up('lg')]: {
    // paddingLeft: 16,
    // paddingRight: 16,
    // paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    // paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingTop: 0,
    paddingBottom: 0,
    // width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

// ----------------------------------------------------------------------

const DashboardLayout = () => {
  const { collapseClick, isCollapse } = useCollapseDrawer();

  const dispatch = useDispatch();

  const { isOpenCall, isOpenHardware } = useSelector((state) => state.settings);

  const { themeLayout } = useSettings();

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);

  const verticalLayout = themeLayout === 'vertical';

  const handleCloseCall = () => {
    dispatch(closeCall());
  };

  const handleCloseHardware = () => {
    dispatch(closeHardware());
  };

  if (verticalLayout) {
    return (
      <>
        <DashboardHeader onOpenSidebar={() => setOpen(true)} verticalLayout={verticalLayout} />

        {isDesktop ? (
          <NavbarHorizontal />
        ) : (
          <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        )}

        <Box
          component="main"
          sx={{
            px: { lg: 2 },
            pt: {
              xs: 0,
              lg: 0,
              // xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              // lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
            },
            pb: {
              xs: 0,
              lg: 0,
              // xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              // lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
            },
          }}
        >
          <Outlet />
        </Box>
      </>
    );
  }

  return (
    <Box
      sx={{
        display: 'block',
        minHeight: { lg: 1 },
      }}
    >
      {/* <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} /> */}

      <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      <MainStyle collapseClick={collapseClick}>
        <Outlet />
      </MainStyle>

      
    </Box>
  );
};

export default DashboardLayout;
