import moment from "moment-timezone";

// ----------------------------------------------------------------------

export function fDate(date) {
  return moment(date).format("DD MMM YYYY");
}

export function fDateTime(date) {
  return moment(date).format("DD MMM YYYY HH:mm");
}

export function fDateHTime(date) {
  return moment(date).format("DD MMM YYYY hh:mm a");
}

export function fHTime(date) {
  return moment(date).format("hh:ss a");
}

export function fTimestamp(date) {
  return moment(date).valueOf();
}

export function fDateTimeSuffix(date) {
  return moment(date).format("DD/MM/YYYY hh:mm a");
}

export function fToNow(date) {
  return moment(date).fromNow();
}
