import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Stack,
  Typography,
  TextField,
} from "@mui/material";
import { TwitchPlayer } from "react-twitch-embed";
import DatePicker from "@mui/lab/DatePicker";
import moment from "moment";

import conObj from "../../utils/connection-assistant";

const LandingPage = () => {
  const [gameInfo, setGameInfo] = useState(null);
  const [liveUpdate, setLiveUpdate] = useState([]);
  const [filterDate, setFilterDate] = useState(new Date());
  const [pastResults, setPastResults] = useState([]);
  const [activeMenu, setActiveMenu] = useState(0);

  const homeRef = useRef(null);
  const liveRef = useRef(null);
  const resultsRef = useRef(null);
  const aboutUsRef = useRef(null);

  useEffect(() => {
    getGameInfo();
    getLiveUpdate();
    getPastResults();

    const liveUpdateInterval = setInterval(() => {
      getLiveUpdate();
    }, 15000); // 15sec

    const pastResultsInterval = setInterval(() => {
      getPastResults();
    }, 15000);

    return () => {
      clearInterval(liveUpdateInterval);
      clearInterval(pastResultsInterval);
    };
  }, []);

  useEffect(() => {
    getPastResults();
  }, [filterDate]);

  const handleDateChange = (date) => {
    setFilterDate(date);
  };

  const getGameInfo = () => {
    conObj.get("/public/gameinfo").then((res) => {
      if (res.status === 200) {
        setGameInfo(res.data);
      }
    });
  };

  const getLiveUpdate = () => {
    conObj.get("/public/LiveUpdate").then((res) => {
      if (res.status === 200) {
        setLiveUpdate(res.data);
      }
    });
  };

  const getPastResults = () => {
    conObj
      .get(`/public/GetDraw/12?date=${moment(filterDate).format("yyyy-MM-DD")}`)
      .then((res) => {
        if (res.status === 200) {
          setPastResults(res.data);
        }
      });
  };

  const CalendarIcon = () => {
    return <img src={require("../../assets/calendar.png")} alt="Calendar" />;
  };

  /**
   * DND Twitch Vars -------------------------------------------------------------------------------------------------------
   */
  const embed = useRef(); // We use a ref instead of state to avoid rerenders.

  const handleReady = useCallback((e) => {
    embed.current = e;
  }, []);

  return (
    <Box
      sx={{
        background: "black",
        height: "fit-content",
        maxWidth: "1500px",
        display: "block",
        mx: "auto",
      }}
    >
      <Box sx={{ maxWidth: "1200px", display: "block", mx: "auto" }}>
        {/* Header Section */}
        <Box sx={{ background: "black" }}>
          <Stack
            direction="row"
            sx={{
              position: "fixed",
              width: "100%",
              maxWidth: "1200px",
              zIndex: 9999,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                pl: { xl: 7, lg: 7, md: 7, sm: 2, xs: 1 },
                pr: { xl: 3, lg: 3, md: 3, sm: 1, xs: 0 },
                background: "black",
              }}
            >
              <img
                src={require("../../assets/rich_lotto_mm_logo.png")}
                alt="App Logo"
                style={{ width: 100, height: "auto" }}
                className="landing-app-logo"
              />
            </Box>
            <Box id="gradient" />
            <Box
              sx={{
                background: "#F0C62E",
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Grid
                container
                sx={{
                  width: {
                    xl: "500px",
                    lg: "500px",
                    md: "500px",
                    sm: "55vw",
                    xs: "65vw",
                  },
                }}
              >
                <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                  <Typography
                    fontWeight={100}
                    color="black"
                    fontFamily="Montserrat"
                    sx={{
                      fontSize: {
                        xl: "1rem",
                        lg: "1rem",
                        md: "1rem",
                        sm: "2vw",
                        xs: "2.5vw",
                      },
                    }}
                    className={
                      activeMenu === 0
                        ? "landing-menu-selected"
                        : "landing-menu"
                    }
                    onClick={() => {
                      setActiveMenu(0);
                      homeRef.current.scrollIntoView({
                        block: "start",
                        behavior: "smooth",
                      });
                    }}
                  >
                    Home
                  </Typography>
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                  <Typography
                    fontWeight={100}
                    color="black"
                    fontFamily="Montserrat"
                    sx={{
                      fontSize: {
                        xl: "1rem",
                        lg: "1rem",
                        md: "1rem",
                        sm: "2vw",
                        xs: "2.5vw",
                      },
                    }}
                    className={
                      activeMenu === 1
                        ? "landing-menu-selected"
                        : "landing-menu"
                    }
                    onClick={() => {
                      setActiveMenu(1);
                      liveRef.current.scrollIntoView({
                        block: "start",
                        behavior: "smooth",
                      });
                    }}
                  >
                    Live
                  </Typography>
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                  <Typography
                    fontWeight={100}
                    color="black"
                    fontFamily="Montserrat"
                    sx={{
                      fontSize: {
                        xl: "1rem",
                        lg: "1rem",
                        md: "1rem",
                        sm: "2vw",
                        xs: "2.5vw",
                      },
                    }}
                    className={
                      activeMenu === 2
                        ? "landing-menu-selected"
                        : "landing-menu"
                    }
                    onClick={() => {
                      setActiveMenu(2);
                      resultsRef.current.scrollIntoView({
                        block: "start",
                        behavior: "smooth",
                      });
                    }}
                  >
                    Results
                  </Typography>
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                  <Typography
                    fontWeight={100}
                    color="black"
                    sx={{
                      fontSize: {
                        xl: "1rem",
                        lg: "1rem",
                        md: "1rem",
                        sm: "2vw",
                        xs: "2.5vw",
                      },
                    }}
                    fontFamily="Montserrat"
                    className={
                      activeMenu === 3
                        ? "landing-menu-selected"
                        : "landing-menu"
                    }
                    onClick={() => setActiveMenu(3)}
                  >
                    About Us
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </Box>

        {/* Main Banner */}
        <Box sx={{ position: "relative" }}>
          <img
            src={require("../../assets/main_banner.png")}
            alt="Main Banner"
            style={{
              width: "100%",
              height: "auto",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              paddingTop: "80px",
            }}
            ref={homeRef}
            className="landing-main-banner"
          />
          <Grid
            container
            sx={{
              position: "absolute",
              bottom: {
                xl: "5.5rem",
                lg: "5.5rem",
                md: "7.5vw",
                sm: "7.5vw",
                xs: "7.5vw",
              },
            }}
          >
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={6}
              sx={{ position: "relative" }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  fontFamily: "Montserrat",
                  fontSize: {
                    xl: "1.5rem",
                    lg: "1.5rem",
                    md: "2vw",
                    sm: "2vw",
                    xs: "2vw",
                  },
                  fontStyle: "italic",
                  fontWeight: 700,
                  textShadow: "2px 4px 4px black",
                  position: "absolute",
                  left: {
                    xl: "7rem",
                    lg: "7rem",
                    md: "9.5vw",
                    sm: "9.5vw",
                    xs: "9.5vw",
                  },
                }}
              >{`UP TO ${gameInfo?.payoutRate}x PAYOUT`}</Typography>
            </Grid>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={6}
              sx={{ position: "relative" }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  fontFamily: "Montserrat",
                  fontSize: {
                    xl: "1.5rem",
                    lg: "1.5rem",
                    md: "2vw",
                    sm: "2vw",
                    xs: "2vw",
                  },
                  fontStyle: "italic",
                  fontWeight: 700,
                  textShadow: "2px 4px 4px black",
                  position: "absolute",
                  right: {
                    xl: "5rem",
                    lg: "5rem",
                    md: "6.5vw",
                    sm: "6.5vw",
                    xs: "6.5vw",
                  },
                }}
              >
                UNLOCK YOUR DREAMS
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            width: { xl: "80%", lg: "80%", md: "80%", sm: "80%", xs: "95%" },
            display: "block",
            mx: "auto",
          }}
        >
          {/* Result Section */}
          <Box sx={{ mt: 3 }}>
            <Box
              sx={{
                // width: "80%",
                display: "block",
                mx: "auto",
                border: "4px solid #F0C62E",
                borderRadius: "20px",
                p: 2,
              }}
            >
              <Grid container>
                <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
                  <img
                    src={require("../../assets/live_logo.png")}
                    alt="Live Logo"
                    className="live-logo"
                    style={{
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  />
                  <Box
                    sx={{
                      width: {
                        xl: "80%",
                        lg: "80%",
                        md: "80%",
                        sm: "80%",
                        xs: "100%",
                      },
                      display: "block",
                      mx: { xl: 0, lg: 0, md: 0, sm: "auto", xs: "auto" },
                    }}
                  >
                    <button
                      style={{
                        border: "1px solid #F0C62E",
                        width: "100%",
                        padding: 5,
                        borderRadius: 50,
                        marginTop: 10,
                        marginBottom: 5,
                      }}
                    >
                      <Typography
                        fontFamily="Montserrat"
                        textAlign="center"
                        color="black"
                        fontWeight={100}
                        sx={{
                          fontSize: {
                            xl: "1rem",
                            lg: "1rem",
                            md: "1rem",
                            sm: "1rem",
                            xs: "3vw",
                          },
                        }}
                      >
                        Watch Now!
                      </Typography>
                    </button>
                  </Box>
                  <Stack direction="row" sx={{ alignItems: "center" }}>
                    <Typography
                      fontWeight={600}
                      fontFamily="Montserrat"
                      color="white"
                      sx={{
                        fontSize: {
                          xl: "1rem",
                          lg: "1rem",
                          md: "1rem",
                          sm: "1rem",
                          xs: "2.7vw",
                        },
                      }}
                    >
                      {moment()
                        .tz("Asia/Yangon")
                        .format("DD MMM YYYY, dddd, h:mm:ss A")
                        .toUpperCase()}
                    </Typography>
                    <img
                      src={require("../../assets/myanmar-flag-circle.png")}
                      style={{ width: 20, height: 20, marginLeft: 5 }}
                      className="myanmar-circle-logo"
                    />
                  </Stack>
                  <Typography
                    fontFamily="Montserrat"
                    fontWeight={600}
                    sx={{
                      fontSize: {
                        xl: "1rem",
                        lg: "1rem",
                        md: "1rem",
                        sm: "1rem",
                        xs: "2.7vw",
                      },
                    }}
                  >
                    <span style={{ fontWeight: 400 }}>next draw:</span>{" "}
                    {liveUpdate?.length > 0
                      ? moment(liveUpdate[0]?.drawCloseAt)
                          .add(20, "minutes")
                          .format("HH:mm A")
                      : "--:-- --"}
                  </Typography>
                </Grid>
                <Grid item xl={7} lg={7} md={7} sm={12} xs={12}>
                  <Box
                    sx={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      mt: { xl: 0, lg: 0, md: 0, sm: 0, xs: "6vw" },
                    }}
                  >
                    <img
                      src={require("../../assets/jackpot_logo.png")}
                      alt="Jackpot Logo"
                      style={{
                        width: "85%",
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                      className="jackpot-logo"
                    />
                    <Typography
                      sx={{
                        position: "absolute",
                        color: "black",
                        right: {
                          xl: "5rem",
                          lg: "5rem",
                          md: "7vw",
                          sm: "10vw",
                          xs: "4vw",
                        },
                        fontFamily: "Montserrat",
                        fontWeight: 900,
                        fontSize: {
                          xl: "1.5rem",
                          lg: "1.5rem",
                          md: "2vw",
                          sm: "3.5vw",
                          xs: "5.5vw",
                        },
                        color: "orange",
                        background:
                          "linear-gradient(0deg, rgb(221, 150, 46),rgb(255, 254, 193), rgb(221, 150, 46))",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                        filter: "drop-shadow(2px 2px 2px black)",
                      }}
                    >{`${gameInfo?.payoutRate}x Payout!`}</Typography>
                  </Box>
                  <Box
                    sx={{
                      width: {
                        xl: "80%",
                        lg: "80%",
                        md: "80%",
                        sm: "80%",
                        xs: "100%",
                      },
                      mx: "auto",
                      display: "block",
                      background: "white",
                      p: 2,
                      borderRadius: "20px",
                      mt: 2,
                    }}
                  >
                    <Grid container>
                      <Grid
                        item
                        xl={4}
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography
                          color="black"
                          sx={{
                            fontSize: {
                              xl: ".85rem",
                              lg: ".85rem",
                              md: ".85rem",
                              sm: ".85rem",
                              xs: "2.3vw",
                            },
                            fontStyle: "italic",
                            fontFamily: "Montserrat",
                          }}
                        >
                          {`Round ${
                            liveUpdate?.length > 0 && liveUpdate[0]?.drawLot
                          }`}
                        </Typography>
                      </Grid>
                      <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                        <Typography
                          color="black"
                          textAlign="center"
                          sx={{
                            fontSize: {
                              xl: "1.7rem",
                              lg: "1.7rem",
                              md: "1.7rem",
                              sm: "1.7rem",
                              xs: "4.5vw",
                            },
                            fontFamily: "Montserrat",
                          }}
                        >
                          {liveUpdate?.length > 0
                            ? liveUpdate[0]?.drawCloseAt
                              ? moment(liveUpdate[0]?.drawCloseAt).format(
                                  "HH:mm"
                                )
                              : "--:--"
                            : "--:--"}
                        </Typography>
                      </Grid>
                      <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                        <Typography
                          color="black"
                          textAlign="end"
                          sx={{
                            fontSize: {
                              xl: "1.7rem",
                              lg: "1.7rem",
                              md: "1.7rem",
                              sm: "1.7rem",
                              xs: "4.5vw",
                            },
                            fontFamily: "Montserrat",
                            fontWeight: 600,
                          }}
                        >
                          {liveUpdate?.length > 0
                            ? liveUpdate[0]?.result
                              ? liveUpdate[0]?.result
                              : "---"
                            : "---"}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid
                        item
                        xl={4}
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography
                          color="black"
                          sx={{
                            fontSize: {
                              xl: ".85rem",
                              lg: ".85rem",
                              md: ".85rem",
                              sm: ".85rem",
                              xs: "2.3vw",
                            },
                            fontStyle: "italic",
                            fontFamily: "Montserrat",
                          }}
                        >
                          {`Round ${
                            liveUpdate?.length > 0 && liveUpdate[1]?.drawLot
                          }`}
                        </Typography>
                      </Grid>
                      <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                        <Typography
                          color="black"
                          textAlign="center"
                          sx={{
                            fontSize: {
                              xl: "1.7rem",
                              lg: "1.7rem",
                              md: "1.7rem",
                              sm: "1.7rem",
                              xs: "4.5vw",
                            },
                            fontFamily: "Montserrat",
                          }}
                        >
                          {liveUpdate?.length > 0
                            ? liveUpdate[1]?.drawCloseAt
                              ? moment(liveUpdate[1]?.drawCloseAt).format(
                                  "HH:mm"
                                )
                              : "--:--"
                            : "--:--"}
                        </Typography>
                      </Grid>
                      <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                        <Typography
                          color="black"
                          textAlign="end"
                          sx={{
                            fontSize: {
                              xl: "1.7rem",
                              lg: "1.7rem",
                              md: "1.7rem",
                              sm: "1.7rem",
                              xs: "4.5vw",
                            },
                            fontFamily: "Montserrat",
                            fontWeight: 600,
                          }}
                        >
                          {liveUpdate?.length > 0
                            ? liveUpdate[1]?.result
                              ? liveUpdate[1]?.result
                              : "---"
                            : "---"}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid
                        item
                        xl={4}
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography
                          color="black"
                          sx={{
                            fontSize: {
                              xl: ".85rem",
                              lg: ".85rem",
                              md: ".85rem",
                              sm: ".85rem",
                              xs: "2.3vw",
                            },
                            fontStyle: "italic",
                            fontFamily: "Montserrat",
                          }}
                        >
                          {`Round ${
                            liveUpdate?.length > 0 && liveUpdate[2]?.drawLot
                          }`}
                        </Typography>
                      </Grid>
                      <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                        <Typography
                          color="black"
                          textAlign="center"
                          sx={{
                            fontSize: {
                              xl: "1.7rem",
                              lg: "1.7rem",
                              md: "1.7rem",
                              sm: "1.7rem",
                              xs: "4.5vw",
                            },
                            fontFamily: "Montserrat",
                          }}
                        >
                          {liveUpdate?.length > 0
                            ? liveUpdate[2]?.drawCloseAt
                              ? moment(liveUpdate[2]?.drawCloseAt).format(
                                  "HH:mm"
                                )
                              : "--:--"
                            : "--:--"}
                        </Typography>
                      </Grid>
                      <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                        <Typography
                          color="black"
                          textAlign="end"
                          sx={{
                            fontSize: {
                              xl: "1.7rem",
                              lg: "1.7rem",
                              md: "1.7rem",
                              sm: "1.7rem",
                              xs: "4.5vw",
                            },
                            fontFamily: "Montserrat",
                            fontWeight: 600,
                          }}
                        >
                          {liveUpdate?.length > 0
                            ? liveUpdate[2]?.result
                              ? liveUpdate[2]?.result
                              : "---"
                            : "---"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>

          {/* Price Structure */}
          <Box>
            <Typography
              color="white"
              sx={{
                pl: "20px",
                fontSize: "1.5rem",
                fontWeight: "600",
                fontFamily: "Montserrat",
                mt: 2,
                mb: { xl: 2, lg: 2, md: 2, sm: "0" },
              }}
            >
              Prize Structure
            </Typography>
            <Box>
              <Grid container>
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={4}
                  xs={4}
                  sx={{
                    p: "5px",
                    background: "#F0C62E",
                    borderTopLeftRadius: "20px",
                    border: "1px solid black",
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "center",
                      color: "black",
                      fontFamily: "Montserrat",
                      fontSize: {
                        xl: "1rem",
                        lg: "1rem",
                        md: "1rem",
                        sm: "1rem",
                        xs: "2.7vw",
                      },
                    }}
                  >
                    Prize Category
                  </Typography>
                </Grid>
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={4}
                  xs={4}
                  sx={{
                    p: "5px",
                    background: "#F0C62E",
                    border: "1px solid black",
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "center",
                      color: "black",
                      fontFamily: "Montserrat",
                      fontSize: {
                        xl: "1rem",
                        lg: "1rem",
                        md: "1rem",
                        sm: "1rem",
                        xs: "2.7vw",
                      },
                    }}
                  >
                    Payout Multiplier
                  </Typography>
                </Grid>
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={4}
                  xs={4}
                  sx={{
                    p: "5px",
                    background: "#F0C62E",
                    borderTopRightRadius: "20px",
                    border: "1px solid black",
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "center",
                      color: "black",
                      fontFamily: "Montserrat",
                      fontSize: {
                        xl: "1rem",
                        lg: "1rem",
                        md: "1rem",
                        sm: "1rem",
                        xs: "2.7vw",
                      },
                    }}
                  >
                    Minimum Buy In
                  </Typography>
                </Grid>

                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={4}
                  xs={4}
                  sx={{
                    p: "5px",
                    background: "white",
                    borderBottomLeftRadius: "20px",
                    border: "1px solid black",
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "center",
                      color: "black",
                      fontFamily: "Montserrat",
                      fontSize: {
                        xl: "1rem",
                        lg: "1rem",
                        md: "1rem",
                        sm: "1rem",
                        xs: "2.7vw",
                      },
                    }}
                  >
                    Jackpot
                  </Typography>
                </Grid>
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={4}
                  xs={4}
                  sx={{
                    p: "5px",
                    background: "white",
                    border: "1px solid black",
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "center",
                      color: "black",
                      fontFamily: "Montserrat",
                      fontSize: {
                        xl: "1rem",
                        lg: "1rem",
                        md: "1rem",
                        sm: "1rem",
                        xs: "2.7vw",
                      },
                    }}
                  >
                    {gameInfo?.payoutRate}x
                  </Typography>
                </Grid>
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={4}
                  xs={4}
                  sx={{
                    p: "5px",
                    background: "white",
                    borderBottomRightRadius: "20px",
                    border: "1px solid black",
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "center",
                      color: "black",
                      fontFamily: "Montserrat",
                      fontSize: {
                        xl: "1rem",
                        lg: "1rem",
                        md: "1rem",
                        sm: "1rem",
                        xs: "2.7vw",
                      },
                    }}
                  >
                    {gameInfo?.minimumBuyIn}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>

          {/* Lotto Description */}
          <Box sx={{ mt: { xl: 4, lg: 4, md: 4, sm: 2, xs: 1 } }}>
            <Grid container spacing={{ xl: 7, lg: 7, md: 7, sm: 7, xs: "2vw" }}>
              <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                <img src={require("../../assets/desc_1.png")} alt="Desc 1" />
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                <img src={require("../../assets/desc_2.png")} alt="Desc 2" />
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                <img src={require("../../assets/desc_3.png")} alt="Desc 3" />
              </Grid>
            </Grid>
          </Box>

          {/* Live Title */}
          <Box sx={{ mt: 3 }}>
            <img
              src={require("../../assets/live_rich_lotto.png")}
              alt="Live Rich Lotto"
              style={{
                width: 400,
                height: "auto",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: 2,
              }}
              className="live-rich-lotto"
            />
          </Box>

          {/* Live Result Section */}
          <Box
            sx={{
              height: {
                xl: "500px",
                lg: "500px",
                md: "500px",
                sm: "400px",
                xs: "260px",
              },
            }}
            ref={liveRef}
          >
            <TwitchPlayer
              channel="yikilotto"
              autoplay
              muted
              onReady={handleReady}
              width={"100%"}
              height={"100%"}
            />
          </Box>

          {/* Past Result Section */}
          <Box sx={{ mt: 4 }} ref={resultsRef}>
            <img
              src={require("../../assets/past_result.png")}
              alt="Past Result"
              style={{
                width: "60%",
                height: "auto",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              className="past-results"
            />
            <Grid container sx={{ mt: 2 }}>
              <Grid container spacing={2}>
                <Grid item xl={7} lg={7} md={7} sm={7} xs={7}>
                  <Grid container>
                    {/* <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                      <Stack
                        direction="row"
                        sx={{
                          border: "2px solid #F0C62E",
                          borderRadius: "20px",
                          p: "5px",
                          background: "white",
                          width: "300px",
                        }}
                      >
                        <img
                          src={require("../../assets/search.png")}
                          alt="Search icon"
                        />
                        <input
                          type="text"
                          placeholder="Search for round, time or round number"
                          style={{
                            marginLeft: 5,
                            border: "none",
                            background: "transparent",
                            width: "100%",
                          }}
                        />
                      </Stack>
                    </Grid> */}
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                      <Stack direction="row">
                        <Box>
                          <DatePicker
                            OpenPickerButtonProps={{
                              style: {
                                background: "white",
                                width: 30,
                                height: 30,
                                border: "2px solid #F0C62E",
                              },
                            }}
                            components={{
                              OpenPickerIcon: CalendarIcon,
                            }}
                            maxDate={new Date()}
                            label=""
                            onChange={handleDateChange}
                            value={filterDate}
                            // onChange={onFilterStartDate}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                variant="standard"
                                sx={{
                                  border: "none",
                                  "& .MuiInput-root": {
                                    color: "black",
                                    "&:before, :after, :hover:not(.Mui-disabled):before":
                                      {
                                        borderBottom: 0,
                                      },
                                  },
                                  color: "black",
                                }}
                                value={`As of ${
                                  filterDate
                                    ? moment(filterDate).format(
                                        "DD/MM/YYYY (dddd)"
                                      )
                                    : ""
                                }`}
                                inputProps={{
                                  style: {
                                    padding: 0,
                                    background: "white",
                                    borderRadius: "20px",
                                    border: "2px solid #F0C62E",
                                    height: 30,
                                    color: "gray",
                                    paddingLeft: 5,
                                    fontSize: ".75rem",
                                    width: "190px",
                                    textAlign: "center",
                                  },
                                }}
                              />
                            )}
                          />
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xl={5} lg={5} md={5} sm={5} xs={5} />
              </Grid>

              <Grid container spacing={2} sx={{ mt: 0 }}>
                <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
                  <Box
                    sx={{
                      background: "rgb(27, 27, 27)",
                      border: "4px solid #F0C62E",
                      height: "100%",
                      p: 2,
                      borderRadius: "20px",
                    }}
                  >
                    <table style={{ width: "100%" }}>
                      <thead>
                        <th>
                          <Typography
                            sx={{
                              textAlign: "left",
                              fontStyle: "italic",
                              fontFamily: "Montserrat",
                              fontWeight: 600,
                            }}
                          >
                            Round
                          </Typography>
                        </th>
                        <th>
                          <Typography
                            sx={{
                              textAlign: "left",
                              fontStyle: "italic",
                              fontFamily: "Montserrat",
                              fontWeight: 600,
                            }}
                          >
                            Time
                          </Typography>
                        </th>
                        <th>
                          <Typography
                            sx={{
                              textAlign: "left",
                              fontStyle: "italic",
                              fontFamily: "Montserrat",
                              fontWeight: 600,
                            }}
                          >
                            Draw
                          </Typography>
                        </th>
                      </thead>
                      <tbody>
                        {pastResults.length > 0
                          ? pastResults.map((item, index) => (
                              <tr>
                                <td>
                                  <Typography
                                    sx={{
                                      fontStyle: "italic",
                                      fontFamily: "Montserrat",
                                    }}
                                  >{`Round ${item.drawLot}`}</Typography>
                                </td>
                                <td>
                                  <Typography
                                    sx={{ fontFamily: "Montserrat" }}
                                  >{`${moment(item.drawCloseAt).format(
                                    "HH:mm"
                                  )}`}</Typography>
                                </td>
                                <td>
                                  <Typography
                                    sx={{
                                      fontFamily: "Montserrat",
                                      fontWeight: 500,
                                    }}
                                  >
                                    {item.result ? item.result : "---"}
                                  </Typography>
                                </td>
                              </tr>
                            ))
                          : "No Data"}
                      </tbody>
                    </table>
                  </Box>
                </Grid>
                <Grid item xl={5} lg={5} md={5} sm={5} xs={12}>
                  <Box
                    sx={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={require("../../assets/jackpot.png")}
                      alt="Jackpot"
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: {
                          xl: "10px",
                          lg: "10px",
                          md: ".7vw",
                          sm: ".7vw",
                          xs: "2vw",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontWeight: 900,
                          fontSize: {
                            xl: "3rem",
                            lg: "3rem",
                            md: "4vw",
                            sm: "4vw",
                            xs: "13vw",
                          },
                          lineHeight: 1,
                          color: "orange",
                          background:
                            "linear-gradient(0deg, rgb(221, 150, 46),rgb(255, 254, 193), rgb(221, 150, 46))",
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                          filter: "drop-shadow(5px 5px 2px black)",
                        }}
                      >{`${gameInfo?.payoutRate}x`}</Typography>
                      <Box
                        sx={{
                          background: "white",
                          py: "5px",
                          px: "20px",
                          borderRadius: "20px",
                        }}
                      >
                        <Typography
                          sx={{
                            textAlign: "center",
                            color: "black",
                            fontSize: {
                              xl: ".75rem",
                              lg: ".75rem",
                              md: "1vw",
                              sm: "1vw",
                              xs: "2vw",
                            },
                            lineHeight: 1,
                          }}
                        >
                          {`As of ${moment()
                            .tz("Asia/Yangon")
                            .format("DD/MM/YYYY (dddd)")}`}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <img
                      src={require("../../assets/rich_lotto_banner.png")}
                      alt="Rich Lotto Banner"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          {/* Footer Section */}
          <Box>
            <img
              src={require("../../assets/rich_lotto_mm_logo.png")}
              alt="App Logo"
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: 32,
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mt: 5,
            }}
          >
            <Grid
              container
              alignItems="center"
              spacing={2}
              sx={{ width: "fit-content", justifyContent: "center" }}
            >
              <Grid item>
                <img
                  src={require("../../assets/18plus_min.png")}
                  alt="sponsor"
                  style={{ height: 30, width: "auto" }}
                />
              </Grid>
              <Grid item>
                <img
                  src={require("../../assets/play_responsibly.png")}
                  alt="sponsor"
                  style={{ height: 15, width: "auto" }}
                />
              </Grid>
              <Grid item>
                <img
                  src={require("../../assets/fairgame.png")}
                  alt="sponsor"
                  style={{ height: 30, width: "auto" }}
                />
              </Grid>
              <Grid item>
                <img
                  src={require("../../assets/custat.png")}
                  alt="sponsor"
                  style={{ height: 30, width: "auto" }}
                />
              </Grid>
              <Grid item>
                <img
                  src={require("../../assets/be_gamble_aware.png")}
                  alt="sponsor"
                  style={{ height: 15, width: "auto" }}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ mt: 5 }}>
            <Typography
              sx={{
                textAlign: "center",
                textDecoration: "underline",
                color: "#F0C62E",
              }}
            >
              Privacy Policy
            </Typography>
          </Box>

          <Typography
            sx={{
              fontSize: ".75rem",
              color: "white",
              textAlign: "center",
              mt: 5,
              fontSize: {
                xl: ".75rem",
                lg: ".75rem",
                md: ".75rem",
                sm: ".75rem",
                xs: "3vw",
              },
            }}
          >
            Rich Lotto has been working in the online gaming market for over 10
            years. With the advanced technical expertise and extensive knowledge
            of the industry, we deliver high quality projects worldwide.
          </Typography>

          <Typography
            sx={{
              fontSize: {
                xl: ".75rem",
                lg: ".75rem",
                md: ".75rem",
                sm: ".75rem",
                xs: "2vw",
              },
              color: "white",
              textAlign: "center",
              mt: 4,
              mb: 10,
            }}
          >
            Copyright © 2023 Rich Lotto Myanmar. All Rights Reserved. Rich Lotto
            Myanmar is a registered brand and trademark of Rich Lotto
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default LandingPage;
