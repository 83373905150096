import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import MainLayout from "../layouts/main";
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// guards
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from "../config";
// components
import LoadingScreen from "../components/LoadingScreen";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "/",
      children: [
        {
          path: "/",
          element: (
            <GuestGuard>
              <LandingPage />
            </GuestGuard>
          ),
        }
      ],
    },
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        { path: "login-unprotected", element: <Login /> },
      ],
    },
    // Live Result - Public
    {
      path: "/live",
      element: (
        <LiveResult />
      ),
    },
    // Live Date Time - Public
    {
      path: "/live-date-time",
      element: (
        <LiveResultDateTimeDisplay />
      ),
    },
    // Games Routes
    {
      path: "/",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: "bet", element: <BetPage /> },
        // { path: "games", element: <GameSelection /> },
        // { path: "games/lottery", element: <LotteryList /> },
        // { path: "games/bet", element: <Bet /> },
        // { path: "games/bet/shoot", element: <ShootNumberPage /> },
      ],
    },

    // {
    //   path: '/',
    //   element: (
    //     <AuthGuard>
    //       <DashboardLayout />
    //     </AuthGuard>
    //   ),
    //   children: [
    //     { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
    //     { path: 'calendar', element: <Calendar /> },
    //   ],
    // },

    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "500", element: <Page500 /> },
        { path: "404", element: <Page404 /> },
        { path: "403", element: <Page403 /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

const LandingPage = Loadable(lazy(() => import("../views/games/LandingPage")));

// AUTHENTICATION
const Login = Loadable(lazy(() => import("../pages/auth/Login")));

// LIVE RESULT
const LiveResult = Loadable(lazy(() => import("../views/games/LiveResult")));

// LIVE RESULT DATE TIME
const LiveResultDateTimeDisplay = Loadable(lazy(() => import("../views/games/LiveResultDateTimeDisplay")));

// GAMES
const GameSelection = Loadable(
  lazy(() => import("../views/games/GameSelection"))
);
const LotteryList = Loadable(lazy(() => import("../views/games/LotteryList")));
const Bet = Loadable(lazy(() => import("../views/games/Bet")));
const BetPage = Loadable(lazy(() => import("../views/games/BetPage")));
const ShootNumberPage = Loadable(
  lazy(() => import("../views/games/ShootNumberPage"))
);

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(
  lazy(() => import("../pages/dashboard/PermissionDenied"))
);

const Page500 = Loadable(lazy(() => import("../pages/Page500")));
const Page403 = Loadable(lazy(() => import("../pages/Page403")));
const Page404 = Loadable(lazy(() => import("../pages/Page404")));
