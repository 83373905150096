import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
import conObj from "../../utils/connection-assistant";

// ----------------------------------------------------------------------
const DEFAULT_UPC = {
  drawId: "",
  drawCloseAt: "",
  drawDateUtc: "",
  drawDate: "",
  drawLot: "",
};

const DEFAULT_GC = {
  countLimitDrawSingleNumber: 0,
  betLimitDrawSingleNumber: 0,
  betLimitSingleNumber: 0,
  betLimitPlayerSingle: 0,
  payoutRate: 0
};

const initialState = {
  liveData: [],
  onGoingData: null,
  gameConfig: DEFAULT_GC,
  upcomingDraw: DEFAULT_UPC,
};

const slice = createSlice({
  name: "lookup",
  initialState,
  reducers: {
    setLiveData(state, action) {
      state.liveData = action.payload;
    },
    setOnGoingData(state, action) {
      state.onGoingData = action.payload;
    },
    setGameConfig(state, action) {
      state.gameConfig = action.payload;
    },
    setUpcomingDraw(state, action) {
      state.upcomingDraw = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setLiveData, setOnGoingData, setGameConfig, setUpcomingDraw } =
  slice.actions;

// Fetchers

export function fetchOnGoingData() {
  return conObj.get("public/getdraw/1").then((response) => {
    dispatch(setOnGoingData(response.data?.[0] || null));
  });
}

export function fetchConfigsAndUpcomings() {
  return conObj.get("Player/UpcomingDraw").then((response) => {
    const data = response.data;
    dispatch(setUpcomingDraw(data?.upcomingDraw || DEFAULT_UPC));
    dispatch(setGameConfig(data?.gameCfg || DEFAULT_GC));
  });
}
